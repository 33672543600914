<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation rn-slick-dot dot-light lightheight"
    >
      <div
        class="slide slide-style-2 slider-box-content without-overlay d-flex align-center bg_image"
        data-black-overlay="1"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  import Login from "@/views/Login";
  import Register from "@/views/Register";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    components: { VueSlickCarousel, Login, Register },
    computed: {
      ...mapGetters (["user"]),
      ...mapGetters (["token"]),
    },
    async  created() {
        await this.getUser();
    },
     methods: {
      ...mapActions(["getUser"]),
      ...mapActions(["getToken"]),
    },
    data() {
      return {
        sliderContent: [
          {
            text_position: "text-left",
            src: require("../../assets/images/bg/questons.jpg"),
            title: "Βαθμίδες και προνόμια",
            desc: `Επιλέξτε και δείτε τις παροχές της κάθε βαθμίδας`,
          },
        ],
        settings: {
          fade: true,
          dots: true,
          arrows: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          margin: 20,
        },
      };
    },
  };
</script>

<style lang="scss">
  .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
  // .v-btn {
  //   text-transform: lowercase;
  // }

  .theme--light.v-image{
    float: right;
  }
  .alignCenter{

    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 8px !important;
    font-weight: 600;
    // border: 2px dashed #f69c55;
  }

  [data-black-overlay]::before {
    background: linear-gradient(to right, red 34%, #5b5b5b 65%);
  }
</style>
