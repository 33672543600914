<template>
  <div>
    <BrandChooseWindow />
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo-all-dark.png" />
    </Header>
    <SliderQuestions />
    <!-- <div class="rn-column-area rn-section-gap mt--150"> -->
    <v-container>
      <v-row>
        <v-col xl="12" lg="12" md="12" sm="12" cols="12">
          <div
            class="single-service service__style--3 text-white mb--50 mt_sm--30"
          >
            <div class="">
              <h2 class="heading-title">Συχνές ερωτήσεις</h2>
              <h3 class="heading-title mt--50">
                Τι είναι η Health_e Bonus Card;
              </h3>
              <p>
                Η Health_e Bonus Card είναι η ψηφιακή κάρτα υγείας, μία κάρτα
                επιβράβευσης από το Hellenic Healthcare Group. Προσφέρει ειδικά
                προνόμια και διευκολύνσεις για ιατρικό έλεγχο, νοσηλεία και
                μεταφορά σε όλα τα θεραπευτήρια (Υγεία, Metropolitan Hospi-tal,
                Μητέρα, Metropolitan General, Λητώ, Creta InterClinic), καθώς
                και στα διαγνωστικά κέντρα HealthSpot του Ομίλου HHG.
              </p>
              <p>
                Με κάθε συναλλαγή, συλλέγετε πόντους και ανταμείβεστε για την
                εμπιστοσύνη σας στην οικογένεια HHG με ακόμα περισσότερες
                παροχές και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό
                όμιλο παροχής υπηρεσιών υγείας στη χώρα μας.
              </p>
              <p>
                Ως κάτοχος της Health_e bonus κάρτας έχετε όλα τα προνόμια
                πρωτοβάθμιας και δευτεροβάθμιας περίθαλψης που σας προσφέρει.
              </p>
              <h3 class="heading-title">
                Σε ποιους απευθύνεται η Health_e Bonus Card;
              </h3>
              <p>
                Κάθε ενήλικο άτομο μπορεί να αποκτήσει Health_e Bonus Card χωρίς
                ηλικιακό όριο. Οι ανήλικοι μπορούν να αποκτήσουν συνδεδεμένη
                ψηφιακή κάρτα υγείας με όλα τα προνόμια της κύριας. Πρακτικά, η
                ψηφιακή κάρτα υγείας είναι για όλους άνδρες, γυναίκες και παιδιά
                κάθε ηλικίας.
              </p>
              <h3 class="heading-title">Πώς αποκτώ την Health_e bonus card;</h3>
              <p>
                Kατεβάστε την εφαρμογή Health_e Bonus Card από το App Store για 
                συσκευές iOS και το Google Play για συσκευές Android. 
                www.healthebonus.gr
              </p>
              <p>
                Η διαδικασία εγγραφής είναι απλή και σύντομη και ολοκληρώνεται 
                οnline από κινητή ή σταθερή συσκευή χωρίς να απαιτείται η επίσκεψη 
                σε Θεραπευτήριο ή Διαγνωστικό Κέντρο του Ομίλου HHG. Κατά την 
                εγγραφή σας στην εφαρμογή Health_e Bonus Card, χρειάζεται να έχετε:
                <ul>
                  <li> ενεργό προσωπικό e-mail</li>
                  <li> προσωπικό αριθμό κινητού τηλεφώνου</li>
                  <li> αριθμό μητρώου κοινωνικής ασφάλισης(ΑΜΚΑ)</li>
                </ul>
              </p>
              <h3 class="heading-title">
                Σε ποια θεραπευτήρια μπορώ να έχω προνόμια;
              </h3>
              <p>
                Μπορείτε να απολαμβάνετε τα ειδικά προνόμια σε όλα τα
                θεραπευτήρια του HHG (Υγεία, Metropolitan Hospital, Μητέρα,
                Metropolitan General, Λητώ, Creta InterClinic), καθώς και στα 4
                διαγνωστικά κέντρα HealthSpot (Κηφισιά, Περιστέρι, Γλυφάδα,
                Πειραιάς).
              </p>
              <h3 class="heading-title">Πώς με επιβραβεύει;</h3>
              <p>Υπάρχουν τέσσερα επίπεδα προνομίων:</p>
              <ul>
                <li>
                  <router-link
                    class="btn-transparent rn-btn-dark"
                    to="/privileges"
                    ><a v-on:click="$store.state.selectedTab = 'white'"
                      >Health_e Bonus White
                      <span style="font-size: 16px"></span></a
                  ></router-link>
                </li>
                <li>
                  <router-link
                    class="btn-transparent rn-btn-dark"
                    to="/privileges"
                    ><a v-on:click="$store.state.selectedTab = 'blue'"
                      >Health_e Bonus Blue
                      <span style="font-size: 16px"></span></a
                  ></router-link>
                </li>
                <li>
                  <router-link
                    class="btn-transparent rn-btn-dark"
                    to="/privileges"
                    ><a v-on:click="$store.state.selectedTab = 'silver'"
                      >Health_e Bonus Silver
                      <span style="font-size: 16px"></span></a
                  ></router-link>
                </li>
                <li>
                  <router-link
                    class="btn-transparent rn-btn-dark"
                    to="/privileges"
                    ><a v-on:click="$store.state.selectedTab = 'gold'"
                      >Health_e Bonus Gold
                      <span style="font-size: 16px"></span></a
                  ></router-link>
                </li>
              </ul>
              <h3 class="heading-title">
                Πώς λειτουργεί η κάρτα και πώς αλλάζω επίπεδο προνομίων;
              </h3>
              <p>
                Mε κάθε συναλλαγή,
                συλλέγετε πόντους. Μόλις συλλέξετε τον απαραίτητο αριθμό πόντων,
                ανεβαίνετε βαθμίδα και απολαμβάνετε ακόμα περισσότερες παροχές
                και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό όμιλο
                παροχής υπηρεσιών υγείας στη χώρα μας. Δηλαδή περνάτε διαδοχικά
                από την Health_e Bonus White στην Health_e Bonus Blue κ.ο.κ.
                Μπορείτε να δείτε αναλυτικά τον αριθμό των πόντων που αφορά κάθε
                κάρτα, καθώς και τον αριθμό των πόντων που αφορά κάθε συναλλαγή
                <router-link class="btn-transparent rn-btn-dark" to="/history"
                  ><a>εδώ <span style="font-size: 16px"></span></a
                ></router-link>
              </p>
              <h3 class="heading-title">
                Τι θα μου κοστίσει η ενεργοποίηση της Health_e Bonus Card ;
              </h3>
              <p>
                Είναι απολύτως δωρεάν, τόσο η αρχική ενεργοποίηση, όσο και
                οποιαδήποτε αναβάθμισή της. Η ψηφιακή κάρτα είναι απολύτως
                προσωπική και μοναδική όσο και ο ΑΜΚΑ σας. Αφορά ενήλικα άτομα
                (18+). Δεν μεταβιβάζεται σε άλλα άτομα και το περιεχόμενό της
                δεν μεταφέρεται σε άλλη κάρτα άλλου ατόμου. Ουσιαστικά πρόκειται
                για την προσωπική σας «θυρίδα» στο δίκτυο HHG όπου καταγράφονται
                και αθροίζονται οι πόντοι που αφορούν τις συναλλαγές σας. Με
                αυτόν τον τρόπο, εξασφαλίζεται αφενός η ασφάλεια των προσωπικών
                σας στοιχείων και αφετέρου η ασφάλεια των συναλλαγών.
              </p>
              <h3 class="heading-title">
                Τι καλύπτει η Health_e bonus Card και πού;
              </h3>
              <p>
                Δείτε αναλυτικά όλες τις υπηρεσίες, παροχές, προνόμια και τις
                εκπτώσεις που προσφέρει κάθε βαθμίδα της Health_e bonus card
                <router-link
                  class="btn-transparent rn-btn-dark"
                  to="/privileges"
                  ><a v-on:click="$store.state.selectedTab = 'gold'"
                    >εδώ <span style="font-size: 16px"></span></a
                ></router-link>
                .
              </p>
              <h3 class="heading-title">
                Τι δεν καλύπτει η Health_e bonus Card;
              </h3>
              <p>
                Οι υπηρεσίες υγείας, τα προνόμια και οι εκπτώσεις
                διαφοροποιούνται ανάλογα με την κάρτα. Όλες οι εκπτώσεις που
                αναφέρονται στις κάρτες εφαρμόζονται στις τιμές του ιδιωτικού
                τιμοκαταλόγου. Από τις εκπτώσεις αυτές εξαιρούνται υλικά, ειδικά
                υλικά, φάρμακα, αμοιβές ιατρών.
              </p>
              <h3 class="heading-title">Γιατί με συμφέρει μια τέτοια κάρτα;</h3>
              <p>
                Γιατί δίνει σε εσάς και την οικογένειά σας προνομιακή πρόσβαση,
                αξιόλογες εκπτώσεις και κάλυψη σχεδόν για οποιοδήποτε πρόβλημα
                αφορά πρωτοβάθμια και δευτεροβάθμια περίθαλψη υγείας από τον
                κορυφαίο όμιλο παροχής υπηρεσιών υγείας της χώρας.
              </p>
              <h3 class="heading-title">
                Όταν επωφελούμαι από τις εκπτώσεις της κάρτας μου παίρνω
                λιγότερους πόντους;
              </h3>
              <p>
                Όχι. Οι πόντοι κάθε συναλλαγής είναι σταθεροί είναι καθορισμένοι
                και μπορείτε να τους δείτε αναλυτικά
                <router-link class="btn-transparent rn-btn-dark" to="/history"
                  ><a>εδώ <span style="font-size: 16px"></span></a></router-link
                >.
              </p>
              <h3 class="heading-title">
                Τι στοιχεία χρειάζονται για την εγγραφή στην εφαρμογή;
              </h3>
              <p>
                Τα στοιχεία που θα σας ζητηθούν είναι Ονοματεπώνυμο, Ημερομηνία
                γέννησης, Κινητό τηλέφωνο, E-mail και ΑΜΚΑ. Επιπλέον, θα πρέπει
                να εισάγετε όνομα και κωδικό χρήστη για να δημιουργηθεί ο
                λογαριασμός.
              </p>
              <h3 class="heading-title">
                Έχω χάσει την κάρτα μου. Τι πρέπει να κάνω;
              </h3>
              <p>
                Η κάρτα δεν υπάρχει σε φυσική παρά μόνο σε ψηφιακή μορφή
                (εφαρμογή σε κινητό τηλέφωνο, tablet).
              </p>
              <h3 class="heading-title">
                Είναι ασφαλής η χρήση της Health_e bonus Card;
              </h3>
              <p>
                Η κάρτα σας είναι άυλη, ψηφιακή και απόλυτα ασφαλής.
                Προστατεύεται από τα πλέον αυστηρά πρωτόκολλα ασφαλείας και
                παρέχει πρόσβαση μόνο στον διαχειριστή της, την εντεταλμένη
                υπηρεσία του Ομίλου HHG. Εσείς δεν χρειάζεται να κάνετε τίποτα
                ούτε ακόμα να θυμάστε κάποιον κωδικό πρόσβασης. <br />
                Το μόνο που χρειάζεται από εσάς για να τη χρησιμοποιήσετε είναι
                να δηλώσετε τα ίδια στοιχεία που είχατε δηλώσει κατά την
                ενεργοποίησή της. Το ειδικό σύστημα του Ομίλου αναλαμβάνει όλα
                τα υπόλοιπα δηλ. ενημέρωση και παροχή των προβλεπόμενων
                υπηρεσιών και εκπτώσεων.
              </p>
              <h3 class="heading-title">
                Η Health_e bonus Card είναι εφ’ όρου ζωής;
              </h3>
              <p>
                Η Health_e Bonus blue Card και η Health_e Bonus silver Card δεν
                έχουν ημερομηνία λήξης ή συγκεκριμένο χρόνο ισχύος καθώς η χρήση
                και αναβάθμισή της εξαρτάται από το χρόνο συγκέντρωσης του
                απαιτούμενου αριθμού πόντων. Η Health_e Bonus gold Card έχει
                διάρκεια αορίστου χρόνου.
              </p>
              <h3 class="heading-title">Απαιτείται ανανέωση ή ενημέρωση;</h3>
              <p>
                Η ενεργοποίηση πραγματοποιείται μία φορά και δηλώνονται και οι
                συνδεδεμένες κάρτες (εφόσον υπάρχουν). Η ενημέρωση και η
                αναβάθμιση γίνονται από την ειδική υπηρεσία του Ομίλου η οποία
                και ενημερώνει τον κάτοχο της Health_e Bonus Card μετά από κάθε
                χρήση της κάρτας.
              </p>
              <h3 class="heading-title">
                Τι γίνεται όσον αφορά τα προστατευόμενα μέλη;
              </h3>
              <p>
                Κατά την ενεργοποίηση της Health_e Bonus Card μπορείτε να δηλώσετε 
                μέχρι και δύο προστατευόμενα μέλη <b>κάτω των 18 ετών</b>. Τα μέλη 
                αυτά έχουν τα ίδια προνόμια με την «κύρια» κάρτα. Οι επισκέψεις/οι 
                πόντοι των συνδεδεμένων καρτών αθροίζονται στην «κύρια» κάρτα, 
                επιταχύνοντας και διευκολύνοντας την αναβάθμισή της.
              </p>
              <h3 class="heading-title">
                Εάν δεν την χρησιμοποιήσω π.χ. για 2 μήνες, χάνω προνόμια;
              </h3>
              <p>
                Όχι. Η κάρτα όμως ακυρώνεται αυτόματα για λόγους ασφαλείας
                εφόσον δεν χρησιμοποιηθεί για 2 χρόνια (24 μήνες). Ωστόσο, η
                ανανέωσή της είναι απλούστατη, εύκολη και εντελώς δωρεάν. <br />
                Εάν η Health_e Bonus Card χρησιμοποιηθεί έστω και μία φορά μέσα
                στο διάστημα ασφαλείας (2 χρόνια), τότε συνεχίζει να ισχύει για
                τα επόμενα 2 χρόνια (24 μήνες) από τη συναλλαγή κ.ο.κ. Γι’ αυτό
                και πρακτικά η κάρτα δεν έχει ημερομηνία λήξης.
              </p>
              <h3 class="heading-title">
                Εάν δεν χρησιμοποιήσω για ένα χρόνο κάποιο από τα προνόμια, αυτό
                «μεταφέρεται» την επόμενη χρονιά; Ή χάνεται;
              </h3>
              <p>
                Ναι, τα προνόμια «μεταφέρονται» με την προϋπόθεση ότι η Health_e
                Bonus Card θα χρησιμοποιηθεί για τουλάχιστον μία φορά μέσα σε 2
                χρόνια (24 μήνες).
              </p>
              <h3 class="heading-title">
                Που μπορώ να μάθω περισσότερες πληροφορίες για την Health_e
                bonus Card;
              </h3>
              <p>
                Για οποιαδήποτε πληροφορία σχετικά με την κάρτα Health_e bonus
                Card, μπορείτε να επικοινωνήσετε στην ηλεκτρονική διεύθυνση
                <a href="mailto:info@hhg.gr">info@hhg.gr</a> ή στη τηλέφωνο +30 210 300 66 34.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <p></p>
    </v-container>
    <!-- </div> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/HeaderOnePage";
import Footer from "@/components/footer/FooterTwo";
import BrandChooseWindow from "@/components/brand/BrandChooseWindow";
import SliderQuestions from "../components/slider/SliderQuestions";

export default {
  components: {
    Header,
    Footer,
    BrandChooseWindow,
    SliderQuestions,
  },
};
</script>
<style scoped>
/* .v-application a {
  color: black;
  text-decoration: underline;
} */
</style>  
